import {ElMessageBox} from "element-plus";
import {BASE_URL, SHOW_LOG} from "@/utils/config";
import axios from "axios";

export default {
    // 判空
    isNull(str) {
        if (str == null || str === "null" || str.length === 0 || /^\s*$/i.test(str)) return true;
        return false;
    }, // 验证手机号码
    isIphone(str) {
        return !/^1([3456789])\d{9}$/.test(str);
    },
    containsLetterAndNumber(str) {
        // 使用正则表达式检查字符串是否包含字母和数字
        var hasLetter = /[a-zA-Z]/.test(str);
        var hasNumber = /\d/.test(str);

        // 返回结果
        return hasLetter && hasNumber;
    },
    isPassword(str) {
        // var testPassword =/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]*$/;
        return /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/.test(str);
    }, toFixed(num, fix) {
        return Math.round(num * fix) / fix;
    }, getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    }, setLocalStorage(key, value) {
        localStorage.setItem(key, value);
    }, clearLocalStorage() {
        localStorage.clear();
    }, formatDate(time, format) { // 格式化时间
        if (time == null || time === "") {
            return ""
        }
        format = format ? format : 'yyyy-MM-dd';
        let date = new Date(time)
        return new Date(date).FormatDataTime(format);
    }, doHandleMonth(number) {
        if (number < 10) {
            return '0' + number;
        } else {
            return number;
        }
    }, /**
     * @param  day为-n，返回的是当前日期的前n天；day为0，返回当前日期，day为n，返回时当前日期的后n天
     * @return {*String} 返回 yyyy-MM-dd 形式
     */
    getDay(day) {
        let today = new Date();
        let targetday_milliseconds = today.getTime() + 1000 * 3600 * 24 * day;
        today.setTime(targetday_milliseconds); //注意，这行是关键代码
        let tMonth = today.getMonth();
        let tDate = today.getDate();


        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        return tMonth + "-" + tDate;
    },
    teamGetDay(date, day) {
        let targetday_milliseconds = date.getTime() + 1000 * 3600 * 24 * day;
        date.setTime(targetday_milliseconds); //注意，这行是关键代码
        let tMonth = date.getMonth();
        let tDate = date.getDate();


        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        return tMonth + "-" + tDate;
    },
    formatDateF(date, day = 0, format = 'ymdhms') {
        let targetday_milliseconds = date.getTime() + 1000 * 3600 * 24 * day;
        date.setTime(targetday_milliseconds); //注意，这行是关键代码
        let tYear = date.getFullYear()
        let tMonth = date.getMonth();
        let tDate = date.getDate();

        let tHour = date.getHours();
        let tMinute = date.getMinutes();
        let tSecond = date.getSeconds();


        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        tHour = this.doHandleMonth(tHour);
        tMinute = this.doHandleMonth(tMinute);
        tSecond = this.doHandleMonth(tSecond);
        if (format === 'ymdhms') {
            return tYear + "-" + tMonth + "-" + tDate + ' ' + tHour + ":" + tMinute + ":" + tSecond;
        } else if (format === 'ymd') {
            return tYear + "-" + tMonth + "-" + tDate;
        } else {
            return tYear + "-" + tMonth + "-" + tDate + ' ' + tHour + ":" + tMinute + ":" + tSecond;

        }
    },
    // 日期取整点
    teamGetTimeOClock(date) {
        // date is 'yyyy-MM-dd HH:mm'
        let tml = date.split(" ");
        let tm = tml[tml.length - 1].split(":");
        return tm[0] + ":00";
    },
    // 日期取时间
    teamGetTimeHm(date) {
        // date is 'yyyy-MM-dd HH:mm'
        let tml = date.split(" ");
        return tml[tml.length - 1]
    },

    getYMD(day=0) {
        let today = new Date();
        let targetday_milliseconds = today.getTime() + 1000 * 3600 * 24 * day;
        today.setTime(targetday_milliseconds); //注意，这行是关键代码
        let tYear = today.getFullYear()
        let tMonth = today.getMonth();
        let tDate = today.getDate();

        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        return tYear + "-" + tMonth + "-" + tDate;
    },

    getYMDFromYMD(date, day) {
        let today = date;
        let targetday_milliseconds = today.getTime() + 1000 * 3600 * 24 * day;
        today.setTime(targetday_milliseconds); //注意，这行是关键代码
        let tYear = today.getFullYear()
        let tMonth = today.getMonth();
        let tDate = today.getDate();

        tMonth = this.doHandleMonth(tMonth + 1);
        tDate = this.doHandleMonth(tDate);
        return tYear + "-" + tMonth + "-" + tDate;
    },

    myPrint(v) {
        if (SHOW_LOG) {
            console.log(v)
        }
    },


    async uploadPic(file, callback, fileName) {
        const uploadUrl = BASE_URL + '/admin/upload/uploadFile'
        const formData = new FormData();
        formData.append("file", file, fileName);
        axios
            .post(uploadUrl, formData)
            .then(response => {

                console.log("文件上传成功", response.data.data);
                callback(response.data.data)
            })
            .catch(error => {
                console.error("文件上传失败", error);
            });
    },


    async compressImage(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const maxWidth = 800;
                    const maxHeight = 600;
                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth || height > maxHeight) {
                        if (width / maxWidth > height / maxHeight) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        } else {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        const compressedFile = new File([blob], file.name, {
                            type: file.type, lastModified: file.lastModified
                        });
                        resolve(compressedFile);
                    }, file.type);
                };
            };

            reader.readAsDataURL(file);
        });
    }

}

export const MyAlert = async (message, showCancel = true, confirm = () => {
}) => {
    await ElMessageBox({
        title: '提示',
        message: message,
        showCancelButton: showCancel,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        beforeClose: (action, instance, done) => {
            confirm(action)
            done()

        }
    }).catch(() => {
    })
}

export const MyAlertInput = async (message, confirm = () => {
}) => {
    await ElMessageBox.prompt(message, '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消',

    })
        .then(({value}) => {
            confirm(value)
        })
        .catch(() => {
        })
}

export const MyAlertNumInput = async (message, confirm = () => {
}) => {
    await ElMessageBox.prompt(message, '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', inputPattern: /\d/,
    })
        .then(({value}) => {
            confirm(value)
        })
        .catch(() => {
        })
}


Date.prototype.FormatDataTime = function (format) {
    if (!format) {
        format = "yyyy-MM-dd HH:mm:ss";
    }
    const o = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "H+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
        "S": this.getMilliseconds()
        // millisecond
    };
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "")
            .substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
};

/*
* 金额格式化
* */

export const moneyFormat = (num, decimal = 2, split = ',') => {
    /*
      parameter：
      num：格式化目标数字
      decimal：保留几位小数，默认2位
      split：千分位分隔符，默认为,
      moneyFormat(123456789.87654321, 2, ',') // 123,456,789.88
    */
    if (isFinite(num)) { // num是数字
        if (num === 0) { // 为0
            return num.toFixed(decimal)
        } else { // 非0
            var res = ''
            var dotIndex = String(num).indexOf('.')
            if (dotIndex === -1) { // 整数
                res = String(num).replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`) + '.' + '0'.repeat(decimal)
            } else { // 非整数
                // js四舍五入 Math.round()：正数时4舍5入，负数时5舍6入
                // Math.round(1.5) = 2
                // Math.round(-1.5) = -1
                // Math.round(-1.6) = -2
                // 保留decimals位小数
                const numStr = String((Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)) // 四舍五入，然后固定保留2位小数
                const decimals = numStr.slice(dotIndex, dotIndex + decimal + 1) // 截取小数位
                res = String(numStr.slice(0, dotIndex)).replace(/(\d)(?=(?:\d{3})+$)/g, `$1${split}`) + decimals
            }
            return res
        }
    } else {
        return '--'
    }
}
// Utils.myPrint('result:', moneyFormat(123456789.87654321)) // '123,456,789.88'

