export const BASE_URL = 'https://admin-api.homeygym.cn'
// export const BASE_URL = 'http://test-admin-api.homeygym.cn'


// 默认每页显示条数
export const DEFAULT_PAGE_SIZE = 10

// 课程类型
// 私教课程
export const CLASS_COACH_TYPE = 'private'

// 团操课程
export const CLASS_GROUP_TYPE = 'team'

// 课包类型
// 私教课包
export const COURSE_COACH_TYPE = 'private'

// 团操课包
export const COURSE_GROUP_TYPE = 'team'

/// 培训类型
export const TRAIN_TYPE = 'train'

// 会员卡
export const MEMBER_CARD_TYPE = 'member'

// 课包
export const PACKAGE_TYPE = 'package'
// 私教课程
export const PRIVATE_TYPE = 'private'

// 培训视频
export const TRAIN_VIDEO = 'video'
// 培训问答
export const TRAIN_ANSWER = 'answer'
// 培训浏览
export const TRAIN_BROWSE = 'browse'

export const STOP_TYPE = 'stop'
export const TURN_TYPE = 'turn'

// 升级类型
// 新合同
export const TYPE_NONE = 'none'
// 会员升级
export const TYPE_MEMBER_UPGRADE = 'memberUpgrade'
// 私教升级
export const TYPE_PRIVATE_UPGRADE = 'privateUpgrade'
// 会员续卡
export const TYPE_MEMBER_CONTINUOUS = 'memberContinuous'

// 是否显示打印内容
// export const SHOW_LOG = true;
export const SHOW_LOG = false;

//

export const PAGE_WHITE_LIST = [
	'detailPage',
	'memberDetail',
	'orderDetail',
	'priceSystem',
	'clazzDetail',
	'storeSetting',
	'articleSetting',
	'accountView',
	'clazzSetting',
	'createTrainingView',
	'storeList',
	'egSetting',
]