import { createStore } from "vuex";

export default createStore({
    // 存储的内容
    state: {
        isEdit: false
    },

    // 同步调用
    mutations: {
        /*
        * 记录是否是可编辑状态
        * */
        isEditFunc(state, isEdit) {
            state.isEdit = isEdit
        }
    },

    // 异步调用
    actions: {
        // isEditFuncSync(state, isEdit) {
        //     Utils.myPrint('这是异步方法,参数为' + isEdit)
        //     state.commit('isEditFunc', isEdit)
        // }
    }
});