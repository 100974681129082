<template>
    <router-view v-slot="{ Component }">
        <keep-alive :max="200" :exclude="whiteList">
            <component :is="Component"></component>
        </keep-alive>
    </router-view>
</template>

<script>
import { onMounted, computed } from "vue";
import Utils from "@/utils/utils";
import { PAGE_WHITE_LIST } from "@/utils/config";
export default {
    name: "App",
    components: {},
    setup() {
        onMounted(() => {
            Utils.myPrint("app");
        });
        const whiteList = computed(() => PAGE_WHITE_LIST);

        return {
            whiteList,
        };
    },
};
</script>

