import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/style/common.scss'
import '@/assets/style/color.scss'
import 'remixicon/fonts/remixicon.css'
import '@/assets/style/layout.css'
import router from './router'
import utils from './utils/utils'
import store from "./store"
import createStore from "./store/vuexStore";
import { createPinia } from 'pinia'

const app = createApp(App)
/*
* 逐个添加ElementPlusIcon组件
* */
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$app = utils;
// 添加路由
app.use(router)
// 使用ElementPlus
app.use(ElementPlus, {
    locale: zhCn,
}
)

app.use(store)
app.use(createStore)
app.use(createPinia())


app.mount('#app')
